import { CheckCertifiedCopyResult, CheckResultType } from "@logion/client";
import { Hash } from "@logion/node-api";
import { useCallback, useState } from "react";

import Icon from "src/common/Icon";

import IconTextRow from "src/common/IconTextRow";
import { ColorTheme } from "src/common/ColorTheme";
import CheckFileResult from "src/components/checkfileresult/CheckFileResult";

import CheckDeliveredButton, { checkResultTypeSpan } from "./CheckDeliveredButton";
import './CheckDeliveredFrame.css';
import InlineDateTime from "src/common/InlineDateTime";
import Frame from "src/common/Frame";

export interface Props {
    checkCertifiedCopy: (hash: Hash) => Promise<CheckCertifiedCopyResult>;
    detailedError: boolean;
    colorTheme?: ColorTheme;
    onChecked?: (result: CheckCertifiedCopyResult) => void;
    icon: string;
    title: string;
    buttonText: string;
}

export default function CheckDeliveredFrame(props: Props) {
    const [ checking, setChecking ] = useState(false);
    const [ checked, setChecked ] = useState(false);
    const [ result, setResult ] = useState<CheckCertifiedCopyResult | undefined>();

    const onChecking = useCallback(() => {
        setChecking(true);
        setResult(undefined);
        setChecked(false);
    }, []);

    const onChecked = useCallback((result: CheckCertifiedCopyResult) => {
        setResult(result);
        setChecked(true);
        if(props.onChecked) {
            props.onChecked(result);
        }
    }, [ props ]);

    return (
        <Frame
            className="CheckDeliveredFrame"
            colorTheme={ props.colorTheme }
            border="2px solid #3B6CF4"
        >
            <IconTextRow
                icon={ <Icon icon={{id: "check_document_tool"}} width="45px" /> }
                text={
                    <>
                        <p className="text-title">{props.title}</p>

                        <p>Check a file to verify if it has been generated by logion and if the related file version belongs to a current NFT owner.</p>
                        <p>This tool will generate the “hash” - a digital fingerprint - of the submitted file, compare it with the file version “hash”
                            previously recorded at the delivery to NFT owner(s). If the check is positive, the file is a logion-certified NFT underlying
                            asset which belongs to a current rightful NFT owner. Otherwise, it will mean that the submitted file has not been generated by
                            logion and/or is not up-to-date (this version is not the currently valid one and/or does not belong to a current rightful NFT owner).</p>

                        <p>Important: the file you submit is NOT uploaded to a server as the test is done by your browser.</p>

                        <CheckDeliveredButton
                            checkCertifiedCopy={ props.checkCertifiedCopy }
                            onChecking={ onChecking }
                            onChecked={ onChecked }
                            buttonText={props.buttonText}
                        />

                        {
                            checking &&
                            <CheckFileResult>
                                {
                                    !checked &&
                                    <div>
                                        <p>Hashing file and checking...</p>
                                    </div>
                                }
                                {
                                    checked && result?.match !== undefined && !props.detailedError &&
                                    <div>
                                        <p>Check result: <strong>{ checkResultTypeSpan(CheckResultType.POSITIVE)  }</strong></p>
                                        <p>The file you uploaded is a logion-certified file which belongs to a current rightful NTF owner.</p>
                                        <ul>
                                            <li>File hash: { result.match.copyHash }</li>
                                            <li>Generation timestamp: <InlineDateTime dateTime={ result.match.generatedOn } separator=" - " /></li>
                                            <li>NFT owner: { result.match.owner }</li>
                                        </ul>
                                        <Icon icon={{id: "ok"}} />
                                    </div>
                                }
                                {
                                    checked && result !== undefined && result.summary === CheckResultType.NEGATIVE && !props.detailedError &&
                                    <div>
                                        <p>Check result: <strong>{ checkResultTypeSpan(CheckResultType.NEGATIVE)  }</strong></p>
                                        <p><strong>No match according to logion records.</strong></p>
                                        <p><strong>Please be careful and execute a deeper due diligence.</strong></p>
                                        <Icon icon={{id: "ko"}} />
                                    </div>
                                }
                                {
                                    checked && result !== undefined && props.detailedError &&
                                    <div>
                                        <p>Check result: <strong>{ checkResultTypeSpan(result.summary)  }</strong></p>
                                        <ul>
                                            <li><strong>Logion origin: { checkResultTypeSpan(result.logionOrigin) }</strong></li>
                                            <li><strong>Belongs to current NFT owner: { checkResultTypeSpan(result.nftOwnership) }</strong></li>
                                            <li><strong>Latest generated file: { checkResultTypeSpan(result.latest) }</strong></li>
                                        </ul>
                                        <Icon icon={{id: result.summary === CheckResultType.NEGATIVE ? "ko" : "ok" }} />
                                    </div>
                                }
                            </CheckFileResult>
                        }
                    </>
                }
            />
        </Frame>
    )
}
